import '../sass/project.scss';

import * as bootstrap from 'bootstrap';

function getNotificationDevices(platform) {
  return new Promise((resolve, reject) => {
    const path = `/api/device/${platform}/`;
    fetch(path, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          resolve(data);
        }).catch((error) => {
          console.error(error);
          reject(error);
        });
      }
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
}

function addNotificationDevice(platform, device) {
  const path = `/api/device/${platform}/`;
  fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    mode: 'same-origin',
    body: JSON.stringify(device),
  }).then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        console.log(data);
      }).catch((error) => {
        console.error(error);
      });
    }
  }).catch((error) => {
    console.error(error);
  });
}

window.onNativePushToken = (token, deviceName) => {
  token = JSON.parse(token);
  deviceName = JSON.parse(deviceName);
  getNotificationDevices(token.type).then((devices) => {
    const device = devices.find((device) => device.registration_id === token.data);
    if (!device) {
      addNotificationDevice(token.type, {
        name: deviceName,
        registration_id: token.data,
        application_id: `${token.type}_app`,
      });
    }
  }).catch((error) => {
    console.error(error);
  });
};

window.onGetCurrentPosition = (position) => {
  alert('Current position: ' + position);
};

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const DESKTOP_THRESHOLD = 768;

const PRIMARY_COLOR = '#0F6ACC';

document.addEventListener('DOMContentLoaded', () => {
  if (window.ReactNativeWebView !== undefined) {
    if (document.getElementById('user_data')) {
      let user_data = JSON.parse(document.getElementById('user_data').textContent);
      if (!user_data) user_data = null;
      window.ReactNativeWebView.postMessage(JSON.stringify({"eventName": "set-user", "data": user_data}));
    }

    window.ReactNativeWebView.postMessage(JSON.stringify({"eventName": "request-native-expo-push-permission", "data": null}));

    // Get all links to /pricing/ and add a click event listener to them
    document.querySelectorAll('a[href="/pricing/"]').forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        window.ReactNativeWebView.postMessage(JSON.stringify({"eventName": "request-native-purchase", "data": null}));
      });
    });

    document.querySelectorAll('a[href="/users/~billing/"]').forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        window.ReactNativeWebView.postMessage(JSON.stringify({"eventName": "request-subscriptions-deep-link", "data": null}));
      });
    });
  }

  if (document.getElementById('incident_date')) {
    incidentListOnLoad();
  }

  if (document.getElementById('incident')) {
    const incident = JSON.parse(
      document.getElementById('incident_json').textContent,
    );
    loadIndividualIncidentMap(incident);

    setupAudioPlayers();

    const adminToggle = document.getElementById('toggleAdminButtons');
    if (adminToggle) {
      adminToggle.addEventListener('click', () => {
        document.querySelectorAll('.admin-only').forEach((element) => {
          element.classList.toggle('d-none');
        });
      });
    }
  }

  const areas_elem = document.getElementById('areas_json');
  if (areas_elem) {
    loadAreaListMap(JSON.parse(areas_elem.textContent));
  }

  if (document.querySelector('.alert-form')) {
    setupNotificationForm();
  }
});

function setupAddressSelector() {
  document
    .querySelectorAll('#div_id_address_wrapper .address-button')
    .forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        const address = event.target.dataset.address;
        // const location = event.target.dataset.location;
        const geocoderElement = document.querySelector(
          '.mapboxgl-ctrl-geocoder--input',
        );
        geocoderElement.value = address;
        geocoderElement.dispatchEvent(new KeyboardEvent('keydown'));
        const observer = new MutationObserver((mutationList) => {
          for (const mutation of mutationList) {
            if (mutation.oldValue === 'display: none;') {
              document
                .querySelector('.suggestions > li:first-of-type')
                .dispatchEvent(new Event('mouseup'));
              return;
            }
          }
        });
        observer.observe(document.querySelector('.suggestions'), {
          attributeFilter: ['style'],
          attributeOldValue: true,
        });
      });
    });
}

function buildIncidentsOverTimeChart(datasets) {
  const data = {
    datasets: datasets,
  };

  const config = {
    type: 'bar',
    data: data,
    options: {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
          },
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
  };

  const ctx = document
    .getElementById('incidents_over_time_chart')
    .getContext('2d');
  new Chart(ctx, config);
}

function buildIncidentsByTimeofdayChart(incidentdata) {
  const data = {
    datasets: [
      {
        label: 'Incident Count',
        data: incidentdata,
      },
    ],
  };
  const config = {
    type: 'bubble',
    data: data,
    options: {
      scales: {
        x: {
          ticks: {
            callback: function (value, index, ticks) {
              return DAYS[value];
            },
          },
        },
        y: {
          reverse: true,
          min: 0,
          max: 24,
          ticks: {
            callback: function (value, index, ticks) {
              const d = new Date();
              d.setHours(value);
              d.setMinutes(0);
              d.setSeconds(0);
              // get the time in 12 hour format
              return d.toLocaleTimeString('en-US', {
                hour: 'numeric',
                hour12: true,
              });
            },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';

              const d = new Date();
              d.setHours(context.raw.y);
              d.setMinutes(0);
              d.setSeconds(0);
              // get the time in 12 hour format
              label +=
                ' at ' +
                d.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  hour12: true,
                }) +
                ' on ' +
                DAYS[context.raw.x];

              if (label) {
                label += ': ';
              }
              if (context.raw.r !== null) {
                label += context.raw.r;
              }
              return label;
            },
          },
        },
      },
    },
  };

  const ctx = document
    .getElementById('incidents_by_timeofday_chart')
    .getContext('2d');
  new Chart(ctx, config);
}

function updateIncidentFilters() {
  const form = document.querySelector('#incident_filters');

  let startDate = form.querySelector('#start_time').value;
  if (startDate) {
    startDate = moment.unix(parseInt(startDate));
  } else {
    startDate = null;
  }
  let endDate = form.querySelector('#end_time').value;
  if (endDate) {
    endDate = moment.unix(parseInt(endDate));
  } else {
    endDate = moment();
  }

  $('#incident_date_hidden').daterangepicker(
    {
      buttonClasses: 'btn',
      applyButtonClasses: 'btn-primary',
      cancelButtonClasses: 'btn-secondary',
      startDate: startDate,
      endDate: endDate,
      ranges: {
        'Last 24 Hours': [moment().subtract(1, 'day'), moment()],
        Yesterday: [
          moment().subtract(1, 'days').startOf('day'),
          moment().subtract(1, 'days').endOf('day'),
        ],
        'Last 7 Days': [
          moment().subtract(6, 'days').startOf('day'),
          moment().endOf('day'),
        ],
        'Last 30 Days': [
          moment().subtract(29, 'days').startOf('day'),
          moment().endOf('day'),
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
      },
    },
    (start, end, label) => {
      // const range = start.format('M/D/YYYY, h:mm a') + ' - ' + end.format('M/D/YYYY, h:mm a');
      form.querySelector('#incident_date').textContent = label;
      form.querySelector('#start_time').value = start ? start.unix() : '';
      form.querySelector('#end_time').value = end ? end.unix() : '';
    },
  );

  const picker = $('#incident_date_hidden').data('daterangepicker');

  form.querySelectorAll('input[name="date_range"]').forEach((element) => {
    element.addEventListener('click', (event) => {
      picker.isShowing = true;
      picker.clickRange.bind(picker)(event);
    });
  });

  for (const elem of form.querySelectorAll('button.dropdown-toggle')) {
    elem.addEventListener('hide.bs.dropdown', () => {
      form.submit();
    });
  }
}

function loadIncidentListMap(
  area,
  incidents,
  custom_area_geojson,
  custom_area_center,
) {
  const map = new mapboxgl.Map({
    container: 'incident_map', // container ID
    style: 'mapbox://styles/mapbox/streets-v12', // style URL
    center: area.location, // starting position [lng, lat]
    zoom: area.map_zoom + 2, // starting zoom
  });

  map.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
    }),
  );

  map.on('load', () => {
    for (const incident of incidents) {
      const incidentId = `incident-${incident.id}`;
      const popup = new mapboxgl.Popup({
        closeButton: false,
        focusAfterOpen: false,
        maxWidth: '300px',
      }).setHTML(
        `<h5><a href="${incident.url}" class="text-decoration-none text-reset stretched-link">${incident.title}</a></h5><h6>${incident.address}</h6><span class="badge text-bg-secondary"><span class="text-${incident.tag_color}"><i class="fa-solid fa-circle"></i></span> ${incident.type}</span>`,
      );
      const marker = new mapboxgl.Marker({
        className: incidentId,
        color: '#0F6ACC',
      })
        .setLngLat(incident.location)
        .setPopup(popup)
        .addTo(map);
      const markerElement = marker.getElement();
      const incidentCard = document.getElementById(incidentId);
      incidentCard.addEventListener('mouseover', () => {
        markerElement.classList.add('hover');
        if (!marker.getPopup().isOpen()) {
          marker.togglePopup();
        }
      });
      incidentCard.addEventListener('mouseout', () => {
        if (marker.getPopup().isOpen()) {
          marker.togglePopup();
        }
        markerElement.classList.remove('hover');
      });
      markerElement.addEventListener('mouseover', () => {
        if (!marker.getPopup().isOpen()) {
          marker.togglePopup();
        }
        incidentCard.classList.add('hover');
      });
      markerElement.addEventListener('click', () => {
        if (window.innerWidth >= DESKTOP_THRESHOLD) {
          incidentCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
      markerElement.addEventListener('mouseout', (event) => {
        const onMouseOut = () => {
          if (marker.getPopup().isOpen()) {
            marker.togglePopup();
          }
          incidentCard.classList.remove('hover');
        };
        if (
          event.relatedTarget &&
          event.relatedTarget.closest('.mapboxgl-popup')
        ) {
          event.relatedTarget
            .closest('.mapboxgl-popup')
            .addEventListener('mouseout', onMouseOut, { once: true });
        } else {
          onMouseOut();
        }
      });
    }
    if (custom_area_geojson) {
      map.addSource('custom_area', {
        type: 'geojson',
        data: custom_area_geojson,
      });
      map.addLayer({
        id: 'custom_area',
        type: 'fill',
        source: 'custom_area',
        layout: {},
        paint: {
          'fill-color': PRIMARY_COLOR,
          'fill-opacity': 0.2,
        },
      });
      map.addLayer({
        id: 'custom_area_outline',
        type: 'line',
        source: 'custom_area',
        layout: {},
        paint: {
          'line-color': PRIMARY_COLOR,
          'line-width': 3,
        },
      });
    }
    if (custom_area_center) {
      map.addSource('custom_area_center', {
        type: 'geojson',
        data: custom_area_center,
      });
      map.addLayer({
        id: 'custom_area_center',
        type: 'symbol',
        source: 'custom_area_center',
        layout: {
          'icon-image': 'dot-10',
          'icon-size': 2,
        },
      });
    }
  });
}

function loadIndividualIncidentMap(incident) {
  const map = new mapboxgl.Map({
    container: 'incident_map', // container ID
    style: 'mapbox://styles/mapbox/streets-v12', // style URL
    center: incident.location, // starting position [lng, lat]
    zoom: 14, // starting zoom
  });

  map.on('load', () => {
    new mapboxgl.Marker({ color: '#0F6ACC' })
      .setLngLat(incident.location)
      .setPopup(
        new mapboxgl.Popup().setHTML(
          `<div><h5>${incident.title}</h5><h6>${incident.address}</h6><span class="badge text-bg-secondary"><span class="text-${incident.tag_color}"><i class="fa-solid fa-circle"></i></span> ${incident.type}</span></div>`,
        ),
      )
      .addTo(map);
  });
}

function loadAreaListMap(areas) {
  const map = new mapboxgl.Map({
    container: 'incident_map', // container ID
    style: 'mapbox://styles/mapbox/streets-v12', // style URL
    center: [-98.58, 39.82], // starting position [lng, lat]
    zoom: 4, // starting zoom
  });

  map.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
    }),
  );

  map.on('load', () => {
    const bounds = new mapboxgl.LngLatBounds();
    for (const area of areas) {
      bounds.extend(area.location);
    }
    // We fit the bounds before adding the markers so that the marker positions are correct when moving the map around
    map.fitBounds(bounds, { padding: 50 });

    for (const area of areas) {
      const areaId = `area-${area.slug}`;
      const popup = new mapboxgl.Popup({
        closeButton: false,
        focusAfterOpen: false,
        maxWidth: '300px',
      });
      if (area.enabled) {
        popup.setHTML(
          `<h5>${area.name}</h5><a href="${area.url}" class="fs-6 fw-bold stretched-link">View Incidents</a>`,
        );
      } else {
        popup.setHTML(`<h5>${area.name}</h5><em>Coming soon</em>`);
      }
      const marker = new mapboxgl.Marker({ color: '#0F6ACC' })
        .setLngLat(area.location)
        .setPopup(popup)
        .addTo(map);
      const markerElement = marker.getElement();
      const areaCard = document.getElementById(areaId);
      areaCard.addEventListener('mouseover', () => {
        markerElement.classList.add('hover');
        marker.togglePopup();
      });
      areaCard.addEventListener('mouseout', () => {
        marker.togglePopup();
        markerElement.classList.remove('hover');
      });
      markerElement.addEventListener('mouseover', () => {
        if (!marker.getPopup().isOpen()) {
          marker.togglePopup();
        }
        areaCard.classList.add('hover');
      });
      markerElement.addEventListener('click', () => {
        if (window.innerWidth >= DESKTOP_THRESHOLD) {
          areaCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
      markerElement.addEventListener('mouseout', (event) => {
        const onMouseOut = () => {
          if (marker.getPopup().isOpen()) {
            marker.togglePopup();
          }
          areaCard.classList.remove('hover');
        };
        if (
          event.relatedTarget &&
          event.relatedTarget.closest('.mapboxgl-popup')
        ) {
          event.relatedTarget
            .closest('.mapboxgl-popup')
            .addEventListener('mouseout', onMouseOut, { once: true });
        } else {
          onMouseOut();
        }
      });

      map.addSource(`area-${area.slug}`, {
        type: 'geojson',
        data: JSON.parse(area.boundary),
      });

      map.addLayer({
        id: `area-${area.slug}`,
        type: 'fill',
        source: `area-${area.slug}`,
        layout: {},
        paint: {
          'fill-color': PRIMARY_COLOR,
          'fill-opacity': 0.2,
        },
      });
      map.addLayer({
        id: `area-outline-${area.slug}`,
        type: 'line',
        source: `area-${area.slug}`,
        layout: {},
        paint: {
          'line-color': PRIMARY_COLOR,
          'line-width': 3,
        },
      });
    }
  });
}

function incidentListOnLoad() {
  const incidents_by_type_elem = document.getElementById('incidents_by_type');
  if (incidents_by_type_elem) {
    // The JSON is double-encoded because it's passed through Django's template system
    buildIncidentsOverTimeChart(
      JSON.parse(JSON.parse(incidents_by_type_elem.textContent)),
    );
  }

  const incidents_by_timeofday_elem = document.getElementById(
    'incidents_by_timeofday',
  );
  if (incidents_by_timeofday_elem) {
    buildIncidentsByTimeofdayChart(
      JSON.parse(JSON.parse(incidents_by_timeofday_elem.textContent)),
    );
  }

  updateIncidentFilters();

  const area = JSON.parse(document.getElementById('area_json').textContent);
  const incidents = JSON.parse(
    document.getElementById('incidents_json').textContent,
  );
  const custom_area_geojson = document.getElementById('custom_area_geojson')
    ? JSON.parse(document.getElementById('custom_area_geojson').textContent)
    : null;
  const custom_area_center = document.getElementById('custom_area_center')
    ? JSON.parse(document.getElementById('custom_area_center').textContent)
    : null;
  loadIncidentListMap(
    area,
    incidents,
    custom_area_geojson ?? JSON.parse(area.boundary),
    custom_area_center,
  );
}

function setupNotificationForm() {
  const addressWrapperElem = document.querySelector('#div_id_address_wrapper');
  const addressRadioBtn = document.querySelector('#id_area_type_0');
  const addressCollapse = new bootstrap.Collapse(addressWrapperElem, {
    toggle: !addressRadioBtn.checked,
  });

  const areaWrapperElem = document.querySelector('#div_id_area_wrapper');
  const areaRadioBtn = document.querySelector('#id_area_type_1');
  const areaCollapse = new bootstrap.Collapse(areaWrapperElem, {
    toggle: !areaRadioBtn.checked,
  });

  const showAddressFields = () => {
    addressCollapse.show();
    areaCollapse.hide();
  };
  const showAreaFields = () => {
    addressCollapse.hide();
    areaCollapse.show();
  };

  addressRadioBtn.addEventListener('change', showAddressFields);
  areaRadioBtn.addEventListener('change', showAreaFields);

  const customRadioButton = document.querySelector(
    '#id_notification_hours input[name="notification_hours"][value="custom"]',
  );
  const timePickerElem = document.querySelector(
    '#id_notification_hours_time_picker',
  );
  const timePickerCollapse = new bootstrap.Collapse(timePickerElem, {
    toggle: !customRadioButton.checked,
  });
  document
    .querySelectorAll('#id_notification_hours input[name="notification_hours"]')
    .forEach((element) => {
      element.addEventListener('change', () => {
        if (element.value === 'custom') {
          timePickerCollapse.show();
        } else {
          timePickerCollapse.hide();
        }
      });
    });

  timePickerElem
    .querySelectorAll('input[type="checkbox"]')
    .forEach((element) => {
      element.addEventListener('change', () => {
        customRadioButton.checked = true;
      });
    });

  setupAddressSelector();
}

function setupAudioPlayers() {
  for (const element of document.querySelectorAll('.audio-player')) {
    const audioElem = element.querySelector('audio');
    audioElem.addEventListener('loadedmetadata', (event) => {
      const durationElem = document.querySelector(
        `#${event.currentTarget.id}-duration`,
      );
      durationElem.textContent = new Date(event.currentTarget.duration * 1000)
        .toISOString()
        .substring(14, 19);
      durationElem.parentElement.classList.remove('d-none');
    });
    audioElem.addEventListener('timeupdate', (event) => {
      const currentTimeElem = document.querySelector(
        `#${event.currentTarget.id}-currentTime`,
      );
      currentTimeElem.classList.remove('d-none');
      currentTimeElem.textContent =
        new Date(event.currentTarget.currentTime * 1000)
          .toISOString()
          .substring(14, 19) + ' /';
      const percentageComplete =
        100 -
        Math.round(
          (event.currentTarget.currentTime / event.currentTarget.duration) *
            100,
        );
      const css = `background-position-x: ${percentageComplete}%;`;
      document
        .querySelector(`#${event.currentTarget.id}`)
        .parentElement.setAttribute('style', css);
    });
    audioElem.addEventListener('ended', (event) => {
      const playButton = document.querySelector(
        `#${event.currentTarget.id}-play`,
      );
      const icon = playButton.querySelector('i.fa-solid');
      icon.classList.remove('fa-pause');
      icon.classList.add('fa-play');
      playButton.querySelector('span.visually-hidden').textContent =
        'Play Audio';
    });
    element.querySelector('button').addEventListener('click', (event) => {
      const audioElem = document.getElementById(
        event.currentTarget.getAttribute('aria-controls'),
      );
      if (audioElem.paused) {
        audioElem.play();
      } else {
        audioElem.pause();
      }
      const oldState = audioElem.paused ? 'pause' : 'play';
      const newState = audioElem.paused ? 'play' : 'pause';
      const icon = event.currentTarget.querySelector('i.fa-solid');
      icon.classList.remove(`fa-${oldState}`);
      icon.classList.add(`fa-${newState}`);
      event.currentTarget.querySelector('span.visually-hidden').textContent =
        `${newState.charAt(0).toUpperCase()}${newState.slice(1)} Audio`;
    });
    element.addEventListener('click', (event) => {
      if (event.target.closest('button')) {
        return;
      }
      // Seek to the clicked position
      const audioElem = element.querySelector('audio');
      const rect = element.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const percentage = x / rect.width;
      audioElem.currentTime = audioElem.duration * percentage;
    });
  }
}
